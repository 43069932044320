import { Link } from 'gatsby';
import * as React from 'react';

const createSlug = (children) => {
  let slug = children;
  if (slug?.length > 10) {
    slug = slug.slice(0, 10);
  }
  slug = slug.replace(' ', '-').replace(/[0-9]/, '').toLowerCase();
  return slug;
};

const heading = (Tag) => (props) => {
  if (Tag !== 'h2') return <Tag {...props} />;
  const slug = createSlug(props.children);
  return (
    <Tag {...props}>
      <Link to={`#${slug}`}>{props.children}</Link>
    </Tag>
  );
};

const components = {
  h1: heading('h1'),
  h2: heading('h2'),
  h3: heading('h3'),
  h4: heading('h4'),
  h5: heading('h5'),
  h6: heading('h6'),
};

export default components;
