const theme = {
  breakpoints: ['40em', '52em', '64em'],
  colors: {
    background: '#fff',
    primary: '#EA0200',
    secondary: '#192437',
    secondaryLight: '#1A2A44',
    tertiary: '#0E1A2D',
    neutralWhite: '#ffffff',
    text: 'neutralWhite',
    borderColor: '#4a4e57',
    neutralBlack: '#172f47',
    primaryDark: '#c30202',
    borderColor2: 'rgb(216, 216, 216)',
  },
  buttons: {
    primary: {
      backgroundColor: 'primary',
      color: 'background',
      height: '5rem',
      fontFamily: 'Proxima Nova Semi Bold',
      fontSize: '15px',
      padding: '22px',
      lineHeight: '29px',
      letterSpacing: '2.35px',
      borderRadius: '0px',
      width: '337px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'primaryDark',
      },
    },
    newClient: {
      cursor: 'pointer',
      fontFamily: 'Abhaya Libre sans-serif',
      fontSize: '16px',
      fontWeight: '700',
      padding: '0.8rem 1.5rem',
    },
  },
  forms: {
    input: {
      default: {
        focusBorderColor: 'none',
      },
    },
  },
  text: {
    heading: {
      color: 'neutralWhite',
      fontFamily: 'merriweather',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem'],
      textAlign: 'center',
      textShadow: '0 2px 5px rgb(0 0 0 / 45%)',
    },
    highlight: {
      color: 'primary',
      fontSize: '23px',
      fontFamily: 'Proxima Nova Bold',
      fontWieght: '400',
      textAlign: 'center',
      textShadow: '0 2px 5px rgb(0 0 0 / 45%)',
      letterSpacing: '7.7px',
    },
  },
  links: {
    nav: {
      height: '3.5rem',
      alignItems: 'center',
      textTransform: 'uppercase',
      letterSpacing: '2.2px',
      fontFamily: 'lato',
      '&:hover': {
        borderBottom: '2px solid',
        borderColor: 'primary',
      },
      secondary: {
        '&:hover': {
          borderBottom: '0px none',
          color: 'tertiary',
        },
      },
    },
    call: {
      backgroundColor: 'secondary',
      border: '1px solid',
      borderColor: 'primary',
      height: '5rem',
      borderRadius: '0px',
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      color: 'neutralWhite',
      padding: '0 20px',
      textDecoration: 'none',
      '&:hover': {
        color: 'neutralWhite',
        backgroundColor: 'primary',
        '.callIcon': {
          backgroundColor: 'white',
          color: 'primary',
        },
        '.phoneNumber': {
          color: 'white',
        },
      },
    },
  },
  fonts: {
    body: 'lato',
    heading: 'Proxima Nova Alt Bold',
  },
  fontWeights: {
    body: 400,
    heading: 700,
  },
  styles: {
    root: {
      body: {
        margin: 0,
      },
      a: {
        cursor: 'pointer',
        color: 'primary',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      h1: {
        color: 'neutralBlack',
        fontFamily: 'merriweather',
        fontSize: '52px',
        letterSpacing: '0.6px',
        lineHeight: '64.5px',
        fontWeight: '400',
        textAlign: 'center',
        a: {
          color: 'primary',
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
      h2: {
        color: 'neutralBlack',
        fontFamily: 'merriweather',
        fontSize: ['35px', null, '52px'],
        letterSpacing: '0.6px',
        lineHeight: '64.5px',
        textAlign: 'center',
        a: {
          color: 'primary',
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
        secondary: {
          color: 'neutralBlack',
          fontFamily: 'merriweather',
          fontSize: '35px',
          letterSpacing: '0.6px',
          lineHeight: '64.5px',
          a: {
            color: 'primary',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          },
        },
      },
      h3: {
        color: 'primary',
        fontSize: '16px',
        letterSpacing: '6.5px',
        lineHeight: '32px',
        fontFamily: 'Proxima Nova Bold',
        textAlign: 'center',
        a: {
          color: 'primary',
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
      h4: {
        fontFamily: 'lato',
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '21.6px',
        marginBlockEnd: '1.33rem',
        a: {
          color: 'primary',
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
      h5: {
        fontFamily: 'lato',
        fontSize: '33px',
        fontWeight: '700',
        marginBlockEnd: '1.33rem',
        a: {
          color: 'primary',
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
      strong: {
        color: 'primary',
        fontFamily: 'lato',
        fontSize: '19.4px',
        fontWeight: '700',
        lineHeight: '24px',
        letterSpacing: '0.2px',
      },
      p: {
        color: 'neutralBlack',
        fontFamily: 'lato',
        fontSize: '18.5px',
        letterSpacing: '-.21px',
        lineHeight: '31.2px',
        fontWieght: '400',
        a: {
          color: 'primary',
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
      ul: {
        marginTop: '0',
        marginBottom: '30px',
        paddingLeft: '35px',
        listStyleType: 'none',
        li: {
          fontFamily: 'lato',
          color: 'neutralBlack',
          fontSize: '18px',
          letterSpacing: '-0.21px',
          lineHeight: '31.14px',
          position: 'relative',
          textAlign: 'left',
          listStyleType: 'none',
          strong: {
            color: 'borderColor',
            fontWeight: '700',
          },
          a: {
            color: 'primary',
            cursor: 'pointer',
            fontFamily: 'lato',
            fontSize: '18px',
            lineHeight: '31.14px',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          },
          '&::before': {
            backgroundColor: 'primary',
            content: '""',
            height: '10px',
            left: '-35px',
            position: 'absolute',
            top: '12px',
            width: '10px',
            borderRadius: '50%',
          },
        },
      },
      em: {
        color: 'primaryDark',
        fontFamily: 'merriweather',
        fontSize: '30px',
        fontStyle: 'italic',
        lineHeight: '31.2px',
        fontWeight: '900',
        a: {
          color: 'primaryDark',
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
  },
};

export default theme;
